.tile {
    width: 5rem;
    height: 5rem;
    border: 1px solid #e3e3e3;
    margin: .3rem;
    display: flex;
}

.tile>span {
    color: #e3e3e3;
    margin: auto;
    font-size: 3rem;
    text-transform: uppercase;
}

.row {
    display: flex;
}

.board {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.heading {
    margin: 0 auto 1rem auto;
    color: #e3e3e3;
}

.game {
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.again {
    margin: .2rem auto 2rem auto;
    color: #ffffff;
}

.button {
    padding: .5rem 2rem;
    border: 4px solid deeppink;
    text-transform: capitalize;
    border-radius: .5rem;
    color: deeppink;
    font-size: 1.4rem;
    font-weight: bold;
    margin: .5rem auto;
}

.subheader {
    margin: .2rem auto;
    color: #ffffff;
}

@media screen and (max-width: 500px) {
    .game {
        width: 100vw;
    }

    .tile {
        width: 3rem;
        height: 3rem;
    }

    .tile>span {
        font-size: 1.6rem;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        caret-color: transparent;
    }
}